/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-01",
    versionChannel: "nightly",
    buildDate: "2023-01-01T00:15:20.282Z",
    commitHash: "b22d75eb32f47d33a410641e46f2e5faaef2c19f",
};
